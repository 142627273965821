<template>

    <div>

        <ve-table :api-mode="false" :columns="columns" :table-data="dayBookEntries" row-key-field-name="DBIdent"
            :expand-option="expandOption" width="100%">
        </ve-table>


    </div>
</template>

<script>

import { Icon } from "@iconify/vue2";

//import functions from "helpers/functions.js"

export default {
    name: "daybooklist",

    props: ["group", "gid"],

    mounted() {



    },

    computed: {
        dayBookEntries() {


            if (this.$store.state.currentGroupUpdate > 3) {
        return []
      }




            let groups = this.$store.state.currentGroup?.group?.students ?? []
            if (groups.length === 0) {
                return []
            }

            let rtn = []




            console.log(groups)

            groups.forEach(x => {

                Object.keys(x.daybook ?? {}).forEach(y => {
                    x.daybook[y].forEach(z => {
                        z.type = y
                        rtn.push(z)
                    })
                })
            })

            rtn.sort(function (a, b) {

                if (a.seen && !b.seen) {
                    return 1
                }

                if (!a.seen && b.seen) {
                    return -1
                }

                if (a.Date > b.Date) {
                    return -1
                }

                if (a.Date < b.Date) {
                    return 1
                }

                return (b.DBIdent - a.DBIdent)


            })

            let y = rtn.reduce((prev, curr) => {
                if (prev.length === 0) {
                    prev.push(curr)
                    return prev
                }

                let last = prev[prev.length - 1]

                if (last.PupilIdent != curr.PupilIdent || last.Date != curr.Date || last.StaffIdent != curr.StaffIdent || last.Note != curr.Note) {
                    prev.push(curr)
                    return prev
                }

                prev[prev.length - 1].Type += " # " + curr.Type

                return prev

            }, [])




            return y;

        }
    },


    data() {

        return {
            columns: [

                {
                    field: "", key: "q", title: "", align: "center",
                    renderBodyCell: ({ row, _column, }, _h) => {

                        let text = null

                        if (row.type == 'B') {
                            text = (<Icon icon="healthicons:loudly-crying" color='red' width="2em" height="2em" />)
                        }
                        else if (row.type == 'G') {
                            text = (<Icon icon="healthicons:happy" style='color: green;' width="2em" height="2em" />)
                        }
                        else {
                            text = (<Icon icon="healthicons:neutral" width="2em" height="2em" />)
                        }

                        if (!row.seen) {
                            return (<div>{text}<br /><Icon icon="ic:outline-fiber-new" color='red' width="2em" height="2em" /></div>)

                        }
                        else {
                            return text
                        }
                    }
                },

                { field: "Date", key: "b", title: "Date", align: "center" },
                {
                    field: "", key: "c", title: "Name", align: "center",
                    renderBodyCell: ({ row, _column, }, _h) => (<p> {row.PupilFirstName + " " + row.PupilLastName}</p>)
                },


                {
                    field: "",
                    key: "a",
                    type: "expand",
                    title: "Click",
                    width: 50,
                    align: "center",
                },

                {
                    field: "", key: "e", title: "Staff Member", align: "center",
                    renderBodyCell: ({ row, _column, }, _h) => (<p> {row.StaffFirstName + " " + row.StaffLastName}</p>)
                },
                //{ field: "date", key: "b", title: "Date", align: "left" },
                //{ field: "hobby", key: "c", title: "Hobby", align: "right" },
                //{ field: "address", key: "d", title: "Address" },
            ],
            expandOption: {
                render: ({ row, _column, _rowIndex }, _h) => (
                    <span>{row.Note}</span>
                )


            },
            localGroup: null,
            loaded: false,
        }

    },


}
</script>

<style>
.ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body .ve-table-expand-tr .ve-table-expand-td-content {
    font-size: 16px;
    padding-left: 15px;
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 5px;
    background-color: lightgrey;

}

.icon-close {
    color: red
}
</style>